import React from "react";
import { useScrollPercentage } from "react-scroll-percentage";
import Title from "../../utils/Title/Title";
import Justine from "../../../assets/justine-tolois.jpg";
import Flavie from "../../../assets/flavie-hespel.jpg";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import Founders from "./../../../assets/portrait.jpg";

export default function SectionOrigin(props: {
    emitFull: (isFull: boolean) => void;
}) {
    const [ref, percentage] = useScrollPercentage();
    const [relativePercentage, setRelativePercentage] = React.useState(0);
    const [lastEmit, setLastEmit] = React.useState(false);
    const { t } = useTranslation();

    React.useEffect(() => {
        const newPercentage = percentage * 2.5;
        if (newPercentage >= 1) {
            setRelativePercentage(1);
            if (lastEmit === false) {
                props.emitFull(true);
                setLastEmit(true);
            }
        } else if (newPercentage <= 0.5 && lastEmit === true) {
            props.emitFull(false);
            setLastEmit(false);
            setRelativePercentage(newPercentage);
        } else {
            setRelativePercentage(newPercentage);
        }
        if (percentage === 1) {
            props.emitFull(false);
            setLastEmit(false);
        }
    }, [lastEmit, percentage, props]);

    return (
        <div className="SectionOrigin" ref={ref}>
            <div
                className="Funder FunderLeft"
                style={{
                    opacity: relativePercentage,
                    transform: `translateX(-${
                        Math.abs(relativePercentage - 1) * 100
                    }%)`,
                }}
            >
                <div className="ImageWrapper">
                    <img
                        src={Justine}
                        alt="Justine Tolois, fondatrice d'Ammonite production"
                    />
                </div>
                <div className="Description">
                    <Fade left>
                        <h3>Justine Tolois</h3>
                        <h4>{t("sectionOrigin.founder")}</h4>
                    </Fade>
                </div>
            </div>
            <div className="Content">
                <Title
                    subTitle={t("sectionOrigin.subTitle")}
                    mainTitle={t("sectionOrigin.title")}
                    align="center"
                    isH1={false}
                />
                <div>
                    <Fade bottom>
                        <p>{t("sectionOrigin.p1")}</p>
                        <p>{t("sectionOrigin.p2")}</p>
                    </Fade>
                </div>
                <div></div>
            </div>
            <div
                className="Funder FunderRight"
                style={{
                    opacity: relativePercentage,
                    transform: `translateX(${
                        Math.abs(relativePercentage - 1) * 100
                    }%)`,
                }}
            >
                <div className="ImageWrapper">
                    <img
                        src={Flavie}
                        alt="Flavie Hespel, co-fondatrice d'Ammonite production"
                    />
                </div>
                <div className="Description">
                    <Fade right>
                        <h3>Flavie Hespel</h3>
                        <h4>{t("sectionOrigin.coFounder")}</h4>
                    </Fade>
                </div>
            </div>
            <div className="Founders">
                <Fade>
                    <img src={Founders} alt="Fondateurs"></img>
                </Fade>
                <div className="Description">
                    <Fade left>
                        <h3>Justine Tolois</h3>
                        <h4>{t("sectionOrigin.founder")}</h4>
                    </Fade>
                </div>
                <div className="Description">
                    <Fade right>
                        <h3>Flavie Hespel</h3>
                        <h4>{t("sectionOrigin.coFounder")}</h4>
                    </Fade>
                </div>
            </div>
        </div>
    );
}
