import React from "react";
import Title from "../../utils/Title/Title";
import { useTranslation } from "react-i18next";
import Anomia from "./../../../assets/anomia.png";
import Sirena from "./../../../assets/sirena.png";
import Addictions from "./../../../assets/addictions.png";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import { FiPlay } from "react-icons/fi";

export default function SectionRealisations() {
    const { t } = useTranslation();

    return (
        <div className="SectionRealisations" id="realisations">
            <Title
                subTitle={t("sectionRealisations.subTitle")}
                mainTitle={t("sectionRealisations.title")}
                align="left"
                isH1={false}
            />
            <div className="Slider">
                <div className="SliderWrapper">
                    <Swiper
                        modules={[
                            Navigation,
                            Pagination,
                            Scrollbar,
                            A11y,
                            Autoplay,
                        ]}
                        spaceBetween={50}
                        slidesPerView={2}
                        navigation={true}
                        autoplay={true}
                        centeredSlides={true}
                        loop={true}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            1000: {
                                slidesPerView: 2,
                            }
                        }}
                    >
                        <SwiperSlide>
                            <div className="ImageWrapper">
                                <img
                                    src={Anomia}
                                    alt={`${t(
                                        "sectionRealisations.musicClip"
                                    )} anomia`}
                                ></img>
                            </div>
                            <a className="External" href="https://www.youtube.com/watch?v=6uAHratGNCo" title={`${t("sectionRealisations.discover")} Anomia`} rel="noreferrer" target="_blank">
                                <FiPlay />
                            </a>
                            <div className="Content">
                                <p>{t("sectionRealisations.musicClip")}</p>
                                <h3>Anomia</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="ImageWrapper">
                                <img
                                    src={Sirena}
                                    alt={`${t(
                                        "sectionRealisations.shortFilm"
                                    )} sirena`}
                                ></img>
                            </div>
                            <div className="Content">
                                <p>{t("sectionRealisations.shortFilm")}</p>
                                <h3>Sirena</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="ImageWrapper">
                                <img
                                    src={Addictions}
                                    alt={`${t(
                                        "sectionRealisations.musicClip"
                                    )} addictions`}
                                ></img>
                            </div>
                            <div className="Content">
                                <p>{t("sectionRealisations.documentary")}</p>
                                <h3>
                                    {t(
                                        "sectionRealisations.projects.addictions"
                                    )}
                                </h3>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
}
