import React from 'react';
import './App.scss';

// Import of components
import HomePage from './components/HomePage/HomePage';
import Loader from './components/utils/Loader/Loader';

function App() {
    const cursorRef = React.createRef<HTMLDivElement>();
    const [isAppLoaded,setIsAppLoaded] = React.useState(false);

    React.useEffect(() => {
        const cursor = cursorRef.current;
        if (cursor) {
            window.addEventListener('mousemove', e => {
                cursor.style.top = e.clientY - cursor.offsetHeight / 2 + 'px';
                cursor.style.left = e.clientX - cursor.offsetWidth / 2 + 'px';
            }, true)
            window.addEventListener('mouseout', () => {
                cursor.style.transform = "scale(0)";
            })
            window.addEventListener('mouseover', () => {
                cursor.style.transform = "scale(1)";
            })

        }
    }, [cursorRef]);

  return (
      <div className="App">
          { isAppLoaded ? <HomePage/> : <Loader emitEnd={(isEnded:boolean) => {setIsAppLoaded(isEnded)}}/> }
      <div ref={cursorRef} className="cursor">
      </div>

    </div>
  );
}

export default App;
