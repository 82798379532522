import React from "react";
import Fade from "react-reveal/Fade";

export default function Title(props: {
  align: "center" | "left";
  subTitle: string;
  mainTitle: { __html: string } | string;
  isH1: boolean | null;
}) {
  if (!props.isH1) {
    return (
      <div className={`title ${props.align}`}>
        <Fade bottom >
          <h3 className="h3">{props.subTitle}</h3>
          <h2 className="h2">{props.mainTitle}</h2>
        </Fade>
      </div>
    );
  } else if (typeof props.mainTitle !== "string") {
    return (
      <div className={`title ${props.align}`}>
        <Fade bottom >
          <h2 className="h3">{props.subTitle}</h2>
          <h1 className="h2" dangerouslySetInnerHTML={props.mainTitle}></h1>
        </Fade>
      </div>
    );
  } else {
    return (
      <div className={`title ${props.align}`}>
        <Fade bottom >
          <h3 className="h3">{props.subTitle}</h3>
          <h2 className="h2">{props.mainTitle}</h2>
        </Fade>
      </div>
    );
  }
}
