import React from 'react'
import { useTranslation } from 'react-i18next';
import Title from '../../utils/Title/Title'
import Masonry from 'react-masonry-css'
import Fade from "react-reveal/Fade";

import PhotoCheval from '../../../assets/gallery-cheval.jpeg';
import PhotoDanse1 from '../../../assets/gallery-danse-1.jpeg';
import PhotoDanse2 from '../../../assets/gallery-danse-2.jpeg';
import PhotoInes from '../../../assets/gallery-ines.jpeg';
import PhotoMirror from '../../../assets/gallery-mirror.jpeg';
import PhotoScarf1 from '../../../assets/gallery-scarf-1.jpeg';
import PhotoScarf2 from '../../../assets/gallery-scarf-2.jpeg';
import PhotoSenem1 from '../../../assets/gallery-senem-1.jpeg';
import PhotoSenem2 from '../../../assets/gallery-senem-2.jpeg';

export default function SectionPhoto(){
    const { t } = useTranslation();
    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        500: 1
      };
    const images : {src: string, alt: string}[] = [
       
        
        {
            src: PhotoInes,
            alt: 'Ines foulard'
        },
        {
            src: PhotoDanse1,
            alt: 'Danseurs 1'
        },
        {
            src: PhotoSenem1,
            alt: 'Senem 1'
        },
        {
            src: PhotoCheval,
            alt: 'Cheval'
        },
        {
            src: PhotoMirror,
            alt: 'Miroir'
        },
        {
            src: PhotoDanse2,
            alt: 'Danseurs 2'
        },
        {
            src: PhotoScarf1,
            alt: 'Foulard 1'
        },
        {
            src: PhotoScarf2,
            alt: 'Foulard 2'
        },
        {
            src: PhotoSenem2,
            alt: 'Senem 2'
        },
        
        
       
    ];
    return (
        <section className='SectionPhoto'>
            <Title subTitle={t('sectionPhoto.subtitle')} mainTitle={t('sectionPhoto.title')} align='left' isH1={false}/>
            <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
            >
                {images.map((i)=>(
                    <Fade bottom >
                    <img src={i.src} alt={i.alt} />
                    </Fade>
                ))}
            </Masonry>
        </section>
    )
}