import React from "react";
import Title from "../../utils/Title/Title";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function SectionIntro() {
  const { t } = useTranslation();
  let dangerousHtml = {
    __html: t("sectionIntro.title"),
  };

    const scrollToRealisation = () => {
        const element = document.getElementById("realisations");
        if (element) {
            element.scrollIntoView({ behavior: "smooth", });
        }
  }

  return (
    <div className="SectionIntro">
      <div className="content">
        <Title
          subTitle={t("sectionIntro.subTitle")}
          mainTitle={dangerousHtml}
          isH1
          align="left"
        />
        <Fade bottom >
          <p>{t("sectionIntro.p1")}</p>
          <p>{t("sectionIntro.p2")}</p>
          <p>{t("sectionIntro.p3")}</p>
        </Fade>
        <Fade >
          <button onClick={() => scrollToRealisation()}>{t("sectionIntro.button")}</button>
        </Fade>
      </div>
      <Fade bottom >
        <div className="image" />
      </Fade>
    </div>
  );
}
