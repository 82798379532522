import React, { Suspense } from 'react'

import "./HomePage.scss"
import SectionContact from './SectionContact/SectionContact'
import SectionIntro from './SectionIntro/SectionIntro'
import SectionLanding from './SectionLanding/SectionLanding'
import SectionOrigin from './SectionOrigin/SectionOrigin'
import SectionPhoto from './SectionPhoto/SectionPhoto'
import SectionRealisations from './SectionRealisations/SectionRealisations'

export default function HomePage() {
    
    const [isFull, setIsFull] = React.useState(false);

    function LocalLoader() {
        return (<div className='bgBlack'></div>)
    }

    return (
        <Suspense fallback={<LocalLoader/>}>
        <div id='HomePage'>
            <div className={`background ${isFull && 'black'}`}></div>
            <SectionLanding/>
            <SectionIntro />
            <SectionOrigin emitFull={(isFull) => { setIsFull(isFull);}}/>
            <SectionRealisations />
            <SectionPhoto/>
            <SectionContact />
        </div>
        </Suspense>
    )
}