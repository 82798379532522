import React from "react";
import LogoAnime from "../../../assets/logoAnime.mp4";

export default function Loader(props: {
    emitEnd: (isEnded:boolean) => void;
}) {
    const [isEnd, setIsEnd] = React.useState(false);

    return (
        <div className={`Loader ${isEnd && "end"}`}>
            <video
                className="logo"
                autoPlay
                muted
                onEnded={() => {
                    setIsEnd(true);
                    setTimeout(() => {
                        props.emitEnd(true);
                    },500);
                }}
            >
                <source src={LogoAnime} />
            </video>
        </div>
    );
}
