import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
  FaEnvelope,
} from "react-icons/fa";

export default function Socials() {
  return (
    <div className="socialLinkContainer">
        <a
          href="https://m.facebook.com/ammoniteproduction/?tsid=0.464573798186969&source=result"
          title="Facebook d'AMMONITE Production"
          className="socialLink"
        >
          <FaFacebook />
        </a>
        <a
          href="https://www.instagram.com/ammonite.production/"
          title="Instagram d'AMMONITE Production"
          className="socialLink"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.youtube.com/channel/UCwO4Z-A28IG5lpRn103R2iQ"
          title="Youtube d'AMMONITE Production"
          className="socialLink"
        >
          <FaYoutube />
        </a>
        <a
          href="https://www.linkedin.com/company/ammonite-production/?viewAsMember=true"
          title="Linkedin d'AMMONITE Production"
          className="socialLink"
        >
          <FaLinkedin />
        </a>
        <a
          href="mailto:contact.ammoniteprod@gmail.com"
          title="Mail d'AMMONITE Production"
          className="socialLink"
        >
          <FaEnvelope />
        </a>
    </div>
  );
}
