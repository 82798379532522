import React from 'react'
import Socials from '../../utils/Socials/Socials'
import Title from '../../utils/Title/Title'
import footerImage from '../../../assets/fondatrices.jpg'
import { useTranslation } from 'react-i18next'
import Fade from "react-reveal/Fade";

export default function SectionContact(){
    const { t } = useTranslation();
    return (
        <footer className="SectionContact">
            <div className="wrapper">
                <Title subTitle={t('sectionContact.subTitle')} mainTitle={t('sectionContact.title')} align="center" isH1={false} />
                <div className="half">
                    <div className="contact-row mt-l">
                        <Fade bottom >
                        <span className="medium">
                            EMAIL
                        </span>
                            <a className='contact-info' href="mailto:">contact@ammoniteproduction.com</a>
                            </Fade>
                    </div>
                    <div className="contact-row">
                    <Fade bottom >
                        <span className="medium">
                            TEL
                        </span>
                            <a className='contact-info' href="tel:">{t('sectionContact.phoneNumber')}</a>
                            </Fade>
                    </div>
                    <div className="contact-row mb-l">
                    <Fade bottom >
                        <span className="medium">
                            LOC
                        </span>
                            <span className='contact-info'>Paris, France</span>
                            </Fade>
                    </div>
                    <Socials />
                </div>
                <div className="half">
                <Fade>
                        <img src={footerImage} alt="Fondatrices d'Ammonite production" />
                        </Fade>
                </div>
                <hr />
                <div className="copyright">© 2022 {t('sectionContact.rights')} | {t('sectionContact.madeBy')} <a href="https://the-crypt-project.com" className='tcp-link' target="_blank" rel='noreferrer'>THE-CRYPT-PROJECT.COM</a></div>
            </div>
        </footer>
    )
}