import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

import logoText from "../../../assets/logo-texte.png";
import logo from "../../../assets/LOGO.png";
import Socials from "../../utils/Socials/Socials";
import bgVideo from "../../../assets/ammoniteproduction_short.mp4";

export default function SectionLanding() {
    const { t, i18n } = useTranslation();
    const [currentLang, setCurrentLang] = React.useState(i18n.language);

    function switchLanguageTo(lang: "fr-FR" | "en-EN") {
        i18n.changeLanguage(lang);
        setCurrentLang(lang);
    }

    return (
        <Suspense fallback="loading">
            <div className="SectionLanding">
                <Fade top delay={500}>
                    <div className="row f-between top">
                        <div className="logo">
                            <img src={logoText} alt="Ammonite production" />
                        </div>
                        <div className="lang">
                            <span
                                onClick={() => switchLanguageTo("fr-FR")}
                                className={
                                    currentLang === "fr-FR" ? "current" : ""
                                }
                            >
                                FR
                            </span>
                            <hr />
                            <span
                                onClick={() => switchLanguageTo("en-EN")}
                                className={
                                    currentLang !== "fr-FR" ? "current" : ""
                                }
                            >
                                EN
                            </span>
                        </div>
                    </div>
                </Fade>
                <div className="logoWrapper">
                        <img src={logo} alt="logo"></img>
                </div>
                <div className="videoWrapper">
                    <div className="video">
                        <video className="backgroundVideo" autoPlay muted loop>
                            <source src={bgVideo} />
                        </video>
                    </div>
                    <div className="slidingTitleWrapper">
                        <h2>
                            <span>{t("sectionLanding.title")}</span>
                            <span>{t("sectionLanding.title")}</span>
                        </h2>
                    </div>
                </div>
                <div className="row f-between bottom">
                    <Fade bottom delay={500}>
                        <div className="scrollDown">
                            {t("sectionLanding.scrollDown")}
                        </div>
                        <Socials />
                    </Fade>
                </div>
            </div>
        </Suspense>
    );
}
